import React from "react";
import { Box, Text, useColorModeValue, VStack } from "@chakra-ui/react";

interface DetailBoxProps {
  details: any[],
  inputDetails: string | undefined,
  tokenHoldersCount: number | null // Added prop for token holders count
}

const DetailBox = ({ details, inputDetails, tokenHoldersCount }: DetailBoxProps) => {
  const textColor = "#ff8400"; // Custom text color for specific details
  const borderColor = useColorModeValue("#DEE5ED", "#f47904");
  const secondaryText = useColorModeValue("#666666", "#DCE6EF");
  const backgroundColor = useColorModeValue("#DEE5ED", "#171717");

  // Function to convert token amount based on its decimals
  const convertTokenAmount = (amount, decimals) => {
    // Ensure decimals is a number
    const decimalPlaces = Number(decimals);
    return Math.round(amount / Math.pow(10, decimalPlaces));
  };

  return (
    <Box
      border={`1px solid ${borderColor}`}
      borderRadius={"6px"}
      p={3}
      my={4}
      mx={3}
      textAlign={"center"}
      backgroundColor={backgroundColor}
    >
      <VStack>
        <Box>
          <Text color={secondaryText} fontSize={"14px"} my={"4px"}>
            Token Address
          </Text>
          <Text
            fontSize={"16px"}
            fontWeight={"400"}
            color={textColor}
            my={"4px"}
          >
            {details.length > 0 ? details[0].name : 'N/A'}
          </Text>
        </Box>

        <Box my={"8px"}>
          <Text color={secondaryText} fontSize={"14px"} my={"4px"}>
            Total Supply
          </Text>
          <Text fontSize={"16px"} color={textColor} my={"4px"}>
            {details.length > 0 && details[0].totalSupply && details[0].decimals 
             ? convertTokenAmount(details[0].totalSupply, details[0].decimals) 
             : 'N/A'}
          </Text>
        </Box>

        <Box my={"8px"}>
          <Text color={secondaryText} fontSize={"14px"} my={"4px"}>
            Contract Address
          </Text>
          <Text fontSize={"16px"} color={textColor} my={"4px"}>
            {details.length > 0 ? details[0].contractAddress : 'N/A'}
          </Text>
        </Box>

        {/* New Box for Token Holders Count */}
        <Box my={"8px"}>
          <Text color={secondaryText} fontSize={"14px"} my={"4px"}>
            Token Holders
          </Text>
          <Text fontSize={"16px"} color={textColor} my={"4px"}>
            {tokenHoldersCount !== null ? tokenHoldersCount : 'N/A'}
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};

export default DetailBox;
