import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  ModalOverlay,
  ModalContent,
  Modal,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useDisclosure,
  useColorModeValue,
  Box,
  Text,
} from "@chakra-ui/react";
import ModalInput from "./input";
import Manage from "./Manage";
import { useActiveWeb3React } from "../../utils/hooks/useActiveWeb3React";
import CurrencyList from "./CurrencyList";
import { Token, Currency, NativeCurrency } from "@uniswap/sdk-core";
import useDebounce from "../../hooks/useDebounce";
import { useNativeBalance } from "../../utils/hooks/useBalances";
import {
  useAllTokens,
  ExtendedEther,
  useToken,
  useIsUserAddedToken,
} from "../../hooks/Tokens";
import { isAddress } from "../../utils";
import { filterTokens } from "./filtering";
import ImportRow from "./ImportRow";
import NewToken from "./newToken";
import {
  useTokenBalance,
  useUpdateBalance,
} from "../../utils/hooks/useUpdateBalances";

const bannedTokens = ["0x9dA970b2465EFC7d9ee34a64523Ae577988398FE", "0x74ce852c927A14D1a3A96F4623224595Ec78a51e", "0x6FF225AA0821F7AD3b5294635f25b456240EC844","0x5Dc73A0904aFf69b4954208354b2D04903F50Bc1", "0x5096EE64a5edBe8fBf577d56c5e86EeFB4afAC90", "0xE547B34541c3243e77291e95095B7402308B8C5D", "0x9ac3C3543Db12888938195F0b9c36872F3D64E9c", "0xD36ECEb8e9219a75fC8e435dC8c36e1F8770320d", "0xA77c8454CB2B4BF3430137D3baBa76A52f3F6f1A", "0xec97Ef67009634C113fBf3430e54fD305B3edD6A", "0xB614C7f3301E842C1B14EE75936E4469843baC01", "0x74ce852c927A14D1a3A96F4623224595Ec78a51e", "0x88AC16884DA32bD21FbA45Cf1ACB6455aabc51C9", "0x9dA970b2465EFC7d9ee34a64523Ae577988398FE"];

type IModal = {
  tokenModal: boolean;
  setTokenModal: React.Dispatch<React.SetStateAction<boolean>>;
  onCurrencySelect: (currency: Currency) => void;
  selectedCurrency?: Currency | null;
  otherSelectedCurrency?: Currency | null;
};

const SelectToken: React.FC<IModal> = ({
  tokenModal,
  setTokenModal,
  onCurrencySelect,
  selectedCurrency,
  otherSelectedCurrency,
}) => {
  const { chainId, account, library } = useActiveWeb3React();
  const [openNewTokenModal, setOpenNewTokenModal] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedQuery = useDebounce(searchQuery, 300);
  const bgColor = useColorModeValue("#FFF", "#191919");
  const boxShadow = useColorModeValue("#DEE6ED", "#f47904");
  const textColor = useColorModeValue("#319EF6", "#4CAFFF");
  const boxColor = useColorModeValue("#F2F5F8", "#191919");

  useEffect(() => {
    setSearchQuery("");
  }, [tokenModal]);

  const [displayManageToken, setDisplayManageToken] = useState(false);
  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      if (currency && 'isToken' in currency && currency.isToken && bannedTokens.includes(currency.address)) {
        // Handle token banned case.
        console.log("This token is not allowed.");
      } else {
        onCurrencySelect(currency);
      }
    },
    [onCurrencySelect]
  );
  
  const allTokens = useAllTokens();
  const searchToken = useToken(debouncedQuery);
  const searchTokenIsAdded = useIsUserAddedToken(searchToken);
  const [Balance, Symbol, Name, Logo] = useNativeBalance();
  const ether = chainId && ExtendedEther(chainId, Symbol, Name, Logo);

  const filteredTokens: Token[] = useMemo(() => {
    return filterTokens(Object.values(allTokens), debouncedQuery);
  }, [allTokens, debouncedQuery]);

  const filteredTokenListWithETH = useMemo((): Currency[] => {
    const s = debouncedQuery.toLowerCase().trim();
    if (s === "" || s === "e" || s === "et" || s === "eth") {
      return ether ? [ether, ...filteredTokens] : filteredTokens;
    }
    return filteredTokens;
  }, [debouncedQuery, ether, filteredTokens]);
  
  const { onClose } = useDisclosure();
  
  const openManageToken = (): void => {
    setDisplayManageToken((state) => !state);
  };
  
  const handleInput = useCallback((event) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
  }, []);

  const [isSearchingForToken, setIsSearchingForToken] = useState(false);
  useEffect(() => {
    if (!searchToken && !(filteredTokenListWithETH?.length > 0)) {
      setIsSearchingForToken(true);
    } else {
      setIsSearchingForToken(false);
    }
  }, [searchToken, filteredTokenListWithETH]);

  const [sortedTokenList] = useUpdateBalance("");

  return (
    <>
      <Modal
        isOpen={tokenModal}
        onClose={() => setTokenModal(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          width='95vw'
          borderRadius='6px'
          bgColor={bgColor}
          minHeight='40vh'
        >
          <ModalHeader fontSize='18px' fontWeight='regular'>
            Select a token
          </ModalHeader>
          <ModalCloseButton
            bg='none'
            size={"sm"}
            mt={3}
            mr={3}
            cursor='pointer'
            _focus={{ outline: "none" }}
            p={"7px"}
            border='1px solid'
          />

          <Box
            width='100%'
            fontSize='14px'
            boxShadow={`0px 1px 0px ${boxShadow}`}
          >
            <Box width='90%' margin='0 auto' pb='5'>
              <ModalInput
                placeholder='Search name or paste address'
                searchQuery={searchQuery}
                changeInput={handleInput}
              />
            </Box>
          </Box>
          <ModalBody maxHeight='60vh' overflowY='scroll' p={0}>
            {isSearchingForToken ? (
              <Text textAlign='center' py='7'>
                Searching...
              </Text>
            ) : searchToken && !searchTokenIsAdded ? (
              <ImportRow
                token={searchToken}
                openNewTokenModal={setOpenNewTokenModal}
              />
            ) : searchQuery !== "" ? (
              filteredTokenListWithETH.map((currency, index) => {
                return (
                  <CurrencyList
                    onCurrencySelect={handleCurrencySelect}
                    key={index}
                    currency={currency}
                    selectedCurrency={selectedCurrency}
                    otherSelectedCurrency={otherSelectedCurrency}
                  />
                );
              })
            ) :
              sortedTokenList?.length > 0 ? (
              sortedTokenList.map((currency, index) => {
                return (
                  <CurrencyList
                    onCurrencySelect={handleCurrencySelect}
                    key={index}
                    currency={currency[0]}
                    selectedCurrency={selectedCurrency}
                    otherSelectedCurrency={otherSelectedCurrency}
                  />
                );
              })
            ) :
             (
              <Text textAlign='center' py='7'>
                No Result found...
              </Text>
            )}
          </ModalBody>

          <ModalFooter py='4' bg={boxColor} borderRadius='6px'>
            <Box w='100%' textAlign='center'>
              <Text
                fontSize='16px'
                color={textColor}
                cursor='pointer'
                onClick={() => openManageToken()}
              >
                Manage Tokens
              </Text>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Manage
        open={displayManageToken}
        setDisplayManageToken={setDisplayManageToken}
        setOpenNewTokenModal={setOpenNewTokenModal}
        openNewTokenModal={openNewTokenModal}
        handleCurrencySelect={handleCurrencySelect}
      />
      {searchToken && openNewTokenModal ? (
        <NewToken
          open={openNewTokenModal}
          handleCurrencySelect={handleCurrencySelect}
          setDisplayImportedToken={setOpenNewTokenModal}
          tokens={[searchToken]}
        />
      ) : null}
    </>
  );
};

export default SelectToken;
