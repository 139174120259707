import React, { useState, useEffect } from "react";
import {
  Flex,
  Input,
  Text,
  Menu,
  Button,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { GetAddressTokenBalance } from "../../../../state/wallet/hooks";
import SelectToken from "../../../../components/Tokens/SelectToken";
import { Currency } from "@uniswap/sdk-core";
import CurrencyLogo from "../../../../components/currencyLogo";
import { escapeRegExp } from "../../../../utils";
import { useUpdateBalance } from "../../../../utils/hooks/useUpdateBalances";

const bannedTokens = ["0x9dA970b2465EFC7d9ee34a64523Ae577988398FE", "0x74ce852c927A14D1a3A96F4623224595Ec78a51e", "0x6FF225AA0821F7AD3b5294635f25b456240EC844","0x5Dc73A0904aFf69b4954208354b2D04903F50Bc1", "0x5096EE64a5edBe8fBf577d56c5e86EeFB4afAC90", "0xE547B34541c3243e77291e95095B7402308B8C5D", "0x9ac3C3543Db12888938195F0b9c36872F3D64E9c", "0xD36ECEb8e9219a75fC8e435dC8c36e1F8770320d", "0xA77c8454CB2B4BF3430137D3baBa76A52f3F6f1A", "0xec97Ef67009634C113fBf3430e54fD305B3edD6A", "0xB614C7f3301E842C1B14EE75936E4469843baC01", "0x74ce852c927A14D1a3A96F4623224595Ec78a51e", "0x88AC16884DA32bD21FbA45Cf1ACB6455aabc51C9", "0x9dA970b2465EFC7d9ee34a64523Ae577988398FE"];

type InputSelectorProps = {
  max: Boolean;
  onCurrencySelect: (currency: Currency | null | undefined) => void;
  currency?: Currency | null;
  otherCurrency?: Currency | null;
  tokenModal: boolean;
  setToken: React.Dispatch<React.SetStateAction<boolean>>;
  onMax?: () => void;
  onUserInput: (value: string) => void;
  value: string | undefined;
  display?:boolean;
  onHover?:()=>void
  disable?:boolean
  placeholder?:string
};

const InputSelector = ({
  max,
  onCurrencySelect,
  currency,
  tokenModal,
  otherCurrency,
  setToken,
  onMax,
  onUserInput,
  value,
  display,
  disable,
  onHover
}: InputSelectorProps) => {
  const [isTokenBanned, setIsTokenBanned] = useState(false);

  useEffect(() => {
    if (currency && 'isToken' in currency && currency.isToken) {
      setIsTokenBanned(bannedTokens.includes(currency.address));
    }
  }, [currency]);

  const handleCurrencySelection = (selectedCurrency: Currency | null | undefined) => {
    if (selectedCurrency && 'isToken' in selectedCurrency && selectedCurrency.isToken && bannedTokens.includes(selectedCurrency.address)) {
      setIsTokenBanned(true);
    } else {
      setIsTokenBanned(false);
      onCurrencySelect(selectedCurrency);
    }
  };

  const inputColor = useColorModeValue("#333333", "#F1F5F8");
  const balanceColor = useColorModeValue("#666666", "#DCE5EF");
  const maxColor = useColorModeValue("#319ef6", "#f47904");
  const maxBgColor = useColorModeValue("#EBF6FE", "#1d1d1d");
  const tokenListTriggerColor = useColorModeValue("", "#DCE5EF");
  const tokenListTrgiggerBgColor = useColorModeValue("", "#1d1d1d");

  const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`);
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput);
    }
  };

  const [balance] = GetAddressTokenBalance(currency ?? undefined);
  
  return (
    <>
      <Flex alignItems='center' mt={3} justifyContent='space-between'>
       {!display && <Input
          fontSize='2xl'
          type='text'
          min='0'
          border='none'
          color={inputColor}
          isRequired
          placeholder='0.0'
          value={value}
          disabled={disable}
          title={disable ? "please fill the input box below, they control this input": undefined}
          onChange={(event) => {
             !disable && enforcer(event.target.value.replace(/,/g, "."));
          }}
          focusBorderColor='none'
          onMouseEnter={disable ? onHover : undefined}
          onMouseLeave={disable ? onHover : undefined}
        />

       } 
        <Flex>
          <Menu>
            <Button
              border='0px'
              h='40px'
              rightIcon={<ChevronDownIcon />}
              mr={display ? "0" : 3}
              bgColor={tokenListTrgiggerBgColor}
              onClick={() => setToken(tokenModal)}
              data-tut='reactour__selectToken'
            >
              <Box mr='3'>
                <CurrencyLogo
                  currency={currency ?? undefined}
                  size={24}
                  squared={true}
                />
              </Box>

              {(currency && currency.symbol && currency.symbol.length > 20
                ? currency.symbol.slice(0, 4) +
                  "..." +
                  currency.symbol.slice(
                    currency.symbol.length - 5,
                    currency.symbol.length
                  )
                : currency?.symbol) || (
                <Text color={tokenListTriggerColor}>Select a token</Text>
              )}
            </Button>
          </Menu>
        </Flex>
      </Flex>
      <Flex mt={3} alignItems='center'>
       {!display && <Text ml={4} color={balanceColor} fontSize='14px'>
          Balance:{" "}
          {balance.currency?.isToken ? balance.toSignificant(6) : balance}{" "}
          {currency?.symbol}
        </Text>}
        {max ? (
          <Text
            ml={2}
            color={maxColor}
            h='22px'
            w='38px'
            px='4px'
            borderRadius='4px'
            bgColor={maxBgColor}
            fontSize='14px'
            onClick={onMax}
            cursor='pointer'
          >
            Max
          </Text>
        ) : (
          <></>
        )}
      </Flex>
      {!isTokenBanned ? (
        <SelectToken
          onCurrencySelect={handleCurrencySelection}
          tokenModal={tokenModal}
          setTokenModal={setToken}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
        />
      ) : (
        <Text color="red.400">This token is not allowed.</Text>
      )}
    </>
  );
};

export default React.memo(InputSelector);
