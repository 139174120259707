import React, { useState, Dispatch, useMemo, useCallback } from 'react';
import {
  useColorModeValue,
  Box,
  Flex,
  Text,
  Link
} from '@chakra-ui/react';
import { useActiveWeb3React } from '../../utils/hooks/useActiveWeb3React';
import ModalInput from './input';
import { useToken } from '../../hooks/Tokens';
import { useUserAddedTokens, useRemoveUserAddedToken } from '../../state/user/hooks';
import { Token } from '@uniswap/sdk-core';
import { isAddress } from '../../utils';
import ImportRow from './ImportRow';
import NewToken from './newToken';
import CurrencyLogo from '../currencyLogo';
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink';
import { ExternalLinkIcon, CloseIcon } from "../../theme/components/Icons"

type IModal = {
  setOpenNewTokenModal: Dispatch<React.SetStateAction<boolean>>
  openNewTokenModal: boolean
  handleCurrencySelect?: (currency: Token) => void
};

// Assuming bannedTokens is an array of addresses for banned tokens.
const bannedTokens = ["0x9dA970b2465EFC7d9ee34a64523Ae577988398FE", "0x74ce852c927A14D1a3A96F4623224595Ec78a51e", "0x6FF225AA0821F7AD3b5294635f25b456240EC844","0x5Dc73A0904aFf69b4954208354b2D04903F50Bc1", "0x5096EE64a5edBe8fBf577d56c5e86EeFB4afAC90", "0xE547B34541c3243e77291e95095B7402308B8C5D", "0x9ac3C3543Db12888938195F0b9c36872F3D64E9c", "0xD36ECEb8e9219a75fC8e435dC8c36e1F8770320d", "0xA77c8454CB2B4BF3430137D3baBa76A52f3F6f1A", "0xec97Ef67009634C113fBf3430e54fD305B3edD6A", "0xB614C7f3301E842C1B14EE75936E4469843baC01", "0x74ce852c927A14D1a3A96F4623224595Ec78a51e", "0x88AC16884DA32bD21FbA45Cf1ACB6455aabc51C9", "0x9dA970b2465EFC7d9ee34a64523Ae577988398FE"];

const ManageToken: React.FC<IModal> = ({
  setOpenNewTokenModal,
  openNewTokenModal,
  handleCurrencySelect
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isTokenBanned, setIsTokenBanned] = useState(false);
  const handleInput = (e: any) => {
    const input = e.target.value;
    setSearchQuery(input);

    // Check if input is one of the banned tokens.
    setIsTokenBanned(bannedTokens.includes(input));
  };
  const { chainId } = useActiveWeb3React()
  const isAddressValid = searchQuery === '' || isAddress(searchQuery)
  // if they input an address, use it
  const searchToken = useToken(searchQuery)

  // all tokens for local list
  const userAddedTokens: Token[] = useUserAddedTokens()
  const removeToken = useRemoveUserAddedToken()

  const tokenList = useMemo(() => {
    return (
      chainId && userAddedTokens.map(token => (
        <Flex key={token.address} my="4" justifyContent="space-between">
          <Flex justifyContent="space-between">
            <CurrencyLogo currency={token} size={24} />
            <Link isExternal href={getExplorerLink(chainId as number, token.address, ExplorerDataType.ADDRESS)} ml="10px">
              {token.symbol}
            </Link>
          </Flex>
          <Flex>
            <Box ml={1} cursor="pointer" onClick={() => {
              setSearchQuery("")
              removeToken(chainId, token.address)
            }}>
              <CloseIcon />
            </Box>

            <Link href={getExplorerLink(chainId as number, token.address, ExplorerDataType.ADDRESS)} ml={3} isExternal>
              <ExternalLinkIcon size="21px" />
            </Link>
          </Flex>
        </Flex>
      ))
    )
  }, [userAddedTokens, chainId, removeToken])

  return (
    <>
      <Box pb="2" pt="3">
        <ModalInput
          placeholder="0x0000"
          searchQuery={searchQuery}
          changeInput={handleInput}
        />
      </Box>
      {!isAddressValid && <Text color="red.400">Enter valid token address</Text>}
      {isTokenBanned && <Text color="red.400">This token is not allowed.</Text>}
      {!isTokenBanned && searchToken && (
        <ImportRow
          token={searchToken}
          openNewTokenModal={setOpenNewTokenModal}
        />
      )}

      {tokenList}
      <Box>
        <Text py="4">
          {userAddedTokens?.length} {userAddedTokens.length === 1 ? 'Custom Token' : 'Custom Tokens'}
        </Text>
      </Box>

      {!isTokenBanned && searchToken && openNewTokenModal ?
        <NewToken
          open={openNewTokenModal}
          handleCurrencySelect={handleCurrencySelect}
          setDisplayImportedToken={setOpenNewTokenModal}
          tokens={[searchToken]}
        /> : null
      }
    </>
  );
};

export default ManageToken;
