import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, useColorModeValue } from '@chakra-ui/react';
import { CloseIcon, AddIcon, SubtractIcon } from '../../../../theme/components/Icons';
import { removeSideTab, checkSideTab } from '../../../../utils/utilsFunctions';
import { ArrowDownIcon } from "@chakra-ui/icons";
import DetailBox from "./DetailBox";
import { animated, Transition } from 'react-spring';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../state';
import { useDetails } from '../../../../utils/hooks/useDetails';
import { detailsTab } from "../../../../state/transaction/actions";
import { GTokenDetailsTab } from '../../../../components/G-analytics/gIndex';
import axios from 'axios';

const ShowDetails = () => {
  const textColor = useColorModeValue('#333333', '#F1F5F8');
  const iconColor = useColorModeValue('#666666', '#DCE5EF');
  const borderColor = useColorModeValue('#DEE5ED', '#161616');
  const boxColor = useColorModeValue("#F2F5F8", "#1d1d1d");
  const [inputTokenDetails, setInputTokenDetails] = useState(null);
  const [outputTokenDetails, setOutputTokenDetails] = useState(null);
  const [inputTokenHoldersCount, setInputTokenHoldersCount] = useState<number | null>(null);
  const [outputTokenHoldersCount, setOutputTokenHoldersCount] = useState<number | null>(null);

  const fetchTokenDetails = async (currencyId: string) => {
    const apiUrl = `https://phoenixplorer.com/api?module=token&action=getToken&contractaddress=${currencyId}`;
    try {
      const response = await axios.get(apiUrl);
      if (response.data.status === '1') {
        return response.data.result;
      } else {
        throw new Error('Failed to fetch token details');
      }
    } catch (error) {
      console.error('Error fetching token details:', error);
      return null;
    }
  };

  const fetchTokenHoldersCount = async (currencyId: string, setCount) => {
    const url = `https://phoenixplorer.com/token-counters?id=${currencyId}`;
    try {
      const response = await axios.get(url);
      setCount(response.data.token_holder_count);
    } catch (error) {
      console.error('Error fetching token holders count:', error);
    }
  };

  const [viewInfo, setViewInfo] = useState<Boolean>(false);
  const swapDetails = useSelector((state: RootState) => state.swap);
  const dispatch = useDispatch<AppDispatch>();
  const sideBarRemoved = useSelector((state: RootState) => state.transactions.removeDetailsTab);

  useEffect(() => {
    const isActive = checkSideTab('details');
    dispatch(detailsTab({ removeDetailsTab: isActive }));

    if (swapDetails.INPUT?.currencyId) {
      fetchTokenDetails(swapDetails.INPUT.currencyId).then(setInputTokenDetails);
      fetchTokenHoldersCount(swapDetails.INPUT.currencyId, setInputTokenHoldersCount);
    }
    if (swapDetails.OUTPUT?.currencyId) {
      fetchTokenDetails(swapDetails.OUTPUT.currencyId).then(setOutputTokenDetails);
      fetchTokenHoldersCount(swapDetails.OUTPUT.currencyId, setOutputTokenHoldersCount);
    }
  }, [swapDetails]);

  return (
    <Flex
      border="1px"
      borderColor={borderColor}
      borderRadius="6px"
      display={sideBarRemoved && 'none'}
      alignItems="center"
    >
      <Box w="100%" my={4} pl={3} pr={3}>
        <Flex alignItems="center" justifyContent="space-between" px={4}>
          <Text fontWeight="400" fontSize="16px" color={textColor}>Details</Text>
          <Flex alignItems="center" fontWeight="bold" rounded={100} bg="#">
            <Flex
              border="2px"
              alignItems="center"
              justifyContent="center"
              mr={2}
              color={iconColor}
              borderColor={iconColor}
              w="22px"
              h="22px"
              borderRadius="6px"
              onClick={() => setViewInfo(!viewInfo)}
              className='Token_Details'
            >
              {viewInfo ? <SubtractIcon onClick={() => setViewInfo(!viewInfo)} /> : <AddIcon onClick={() => setViewInfo(!viewInfo)} />}
            </Flex>
            <Flex
              border="2px"
              alignItems="center"
              justifyContent="center"
              color={iconColor}
              borderColor={iconColor}
              w="22px"
              h="22px"
              borderRadius="6px"
              cursor="pointer"
              onClick={() => {
                dispatch(detailsTab({ removeDetailsTab: true }));
                removeSideTab('details');
              }}
            >
              <CloseIcon />
            </Flex>
          </Flex>
        </Flex>
        <Transition
          items={viewInfo}
          from={{ opacity: 0, x: 0, y: -100 }}
          enter={{ opacity: 1, x: 0, y: 0 }}
          leave={{ opacity: 0, x: 0, y: -100 }}
          delay={1}
        >
          {(styles, item) => item && (
            <animated.div style={styles}>
              <DetailBox
                details={inputTokenDetails ? [inputTokenDetails] : []}
                inputDetails={swapDetails.INPUT.currencyId}
                tokenHoldersCount={inputTokenHoldersCount}
              />
              <Flex justifyContent={'center'}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  width="40px"
                  height="40px"
                  my={'8px'}
                  bgColor={boxColor}
                  border={`3px solid ${borderColor}`}
                  boxSizing="border-box"
                  borderRadius="12px"
                >
                  <ArrowDownIcon w={5} h={10} />
                </Box>
              </Flex>
              <DetailBox
                details={outputTokenDetails ? [outputTokenDetails] : []}
                inputDetails={swapDetails.OUTPUT.currencyId}
                tokenHoldersCount={outputTokenHoldersCount}
              />
            </animated.div>
          )}
        </Transition>
      </Box>
    </Flex>
  );
};

export default ShowDetails;
